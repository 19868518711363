import { sanityImage } from '@/utils/sanityImage';
import { useLocaleField } from '@/hooks/useLocaleField';
import { Flex, Stack, Text, VStack } from '@chakra-ui/react';
import { FC, useMemo } from 'react';
import { MotionBox } from '../MotionBox';
import styles from './PageCover.module.css';
import { PageCoverProps } from './PageCover.props';

const PageCover: FC<PageCoverProps> = ({ data }) => {
  const { getLocaleField } = useLocaleField();
  const backgroundImage = useMemo<string | undefined>(() => {
    if (data?.cover_image) {
      return `url(${sanityImage(data?.cover_image)?.width(1920).height(400).url()})`;
    }

    return undefined;
  }, [data?.cover_image]);

  return (
    <Flex
      w="full"
      h="400px"
      backgroundImage={backgroundImage}
      backgroundSize="cover"
      backgroundPosition="center center"
    >
      <VStack w="full" justify="center" bgGradient="linear(to-r, blackAlpha.600, transparent)">
        <Stack
          backdropFilter="blur(5px)"
          backgroundColor="#A2906022"
          maxW="2xl"
          align="flex-start"
          p="15px"
          spacing={6}
        >
          <MotionBox
            initial={{ x: 10, opacity: 0 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2, duration: 1, type: 'spring' }}
          >
            <Text
              color="white"
              fontWeight={700}
              lineHeight={1.2}
              fontFamily="heading"
              fontSize={{ base: '1.8rem', md: '3rem' }}
            >
              {getLocaleField(data?.title)}
            </Text>
          </MotionBox>
        </Stack>
      </VStack>
    </Flex>
  );
};

export { PageCover };
